import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import SimpleMap from "../components/simplemap"
import scrollTo from "gatsby-plugin-smoothscroll"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import EnquiryForm from "../components/enquiry"
import NearBy from "../components/nearby"
import VizSensor from "react-visibility-sensor"
import moment from "moment"
import { nodeName } from "jquery"
import RelatedSpaces from "../components/related-spaces"
import CoworkingRelated from "../components/coworkingrelated"
const CoworkingSpace = props => {
  const [currentTab, setcurrentTab] = useState("overview")
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })
  const [showPhotos, setshowPhotos] = useState(false)
  const [reviewTab, setReviewTab] = useState("GoFloaters")
  const [FAQ, setFAQ] = useState(0)
  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener("scroll", updatePosition)
    updatePosition()

    return () => window.removeEventListener("scroll", updatePosition)
  }, [])
  // console.log(scrollPosition)
  const post = props.data.listings
  //const center = { lat: post.location[0], lng: post.location[1] }
  var activeClass = ""

  const Photos = post.photos?.map((photo, index) => (
    <div className="CoworkingimageBox">
      <img
        src={photo?.replace(
          "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
          "https://cdn.app.gofloaters.com"
        )}
        className="d-block w-100 img-responsive"
        alt={post.spaceGFName + " " + post.spaceDisplayName}
      ></img>
    </div>
  ))

  const Reviews = post.spaceFeedback
    ? post.spaceFeedback
        .filter(review => review.commentForSpaces) // Filter out empty comments
        .map((review, index) => (
          <div className="reviewBox" key={index}>
            <p>{review.commentForSpaces.slice(0, 50)} ...</p>
          </div>
        ))
    : null

  const Photos2 = post.photos.map((photo, index) => (
    <div className="col-md-9">
      <img
        src={photo?.replace(
          "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
          "https://cdn.app.gofloaters.com"
        )}
        className="img-responsive"
        alt={post.spaceGFName + " " + post.spaceDisplayName}
      ></img>
    </div>
  ))

  const TimeFormat = time => {
    const t = new Date(time)
    const formatted = moment(t).format("MMMM d, YYYY")
    return formatted
  }

  const ratingText = rating => {
    if (rating > 4.5) {
      return "Very Good"
    } else if (rating >= 3.5 && rating <= 4.5) {
      return "Good"
    } else if (rating >= 2.5 && rating <= 3.5) {
      return "Average"
    } else if (rating < 2.5) {
      return "Below Average"
    }
  }

  const spaceFeedbackComp = post.spaceFeedback
    ?.sort((a, b) => (a.feedbackDate < b.feedbackDate ? 1 : -1))
    .map(dataDrop => {
      if (dataDrop.customerName) {
        return (
          <div className="ratingCard">
            <div className="row">
              <div className="col-md-12">
                <p className="customerName">{dataDrop.customerName}</p>
              </div>
              <div className="col-md-12">
                <p>{dataDrop.commentForSpaces}</p>
              </div>
              <div className="col-md-9 col-6">
                <p>
                  <div>
                    <span
                      class={
                        "stars-container stars-" +
                        Number(20) * Number(dataDrop.rating)
                      }
                    >
                      ★★★★★
                    </span>
                  </div>
                </p>
              </div>
              <div className="col-md-3 col-6 text-right">
                <p style={{ fontSize: "12px" }}>
                  {TimeFormat(dataDrop.feedbackDate)}
                </p>
              </div>
            </div>
          </div>
        )
      }
    })
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  let SpaceSuitedFor = ""
  if (post.purposesList) {
    SpaceSuitedFor = post.purposesList.map((purpose, index) => (
      <div className="SpaceSuitedForAlter" key={index}>
        <p>{purpose}</p>
      </div>
    ))
  }

  let operationTime = post.operationTime

  if (post.operationTime !== "") {
    operationTime = post.operationTime
      .split(", ")
      .map((operationTime, index) => (
        <div className="col-md-4  ">
          <div className="OperationTime row aligner" key={index}>
            <div className="col-2 text-center">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                {" "}
                <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
              </svg>{" "}
            </div>
            <div className="col-10">
              <p className="OperationTimeText">
                <span>{operationTime.split(":")[0]}</span>
                <br></br>
                {operationTime.split(" : ")[1]}
              </p>
            </div>
          </div>
        </div>
      ))
  }

  var Title = ""
  if (post.spaceType === "Cafe") {
    Title = post.spaceDisplayName + " - " + post.spaceAddress
  } else {
    Title =
      post.spaceGFName +
      " - " +
      post.OriginalName +
      " - " +
      post.spaceDisplayName +
      " | Meeting Space near " +
      post.spaceAddress +
      ", "
  }
  function tConv24(time24) {
    var ts = time24
    var H = +ts.substr(0, 2)
    var h = H % 12 || 12
    h = h < 10 ? "0" + h : h // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM"
    ts = h + ts.substr(2, 3) + ampm
    return ts
  }
  var today = new Date()
  var day = today.getDay()

  var daylist = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  var todayDay = daylist[day]
  function timeCal(todayDay) {
    switch (todayDay) {
      case "Monday":
        if (post.monday.holiday) return "Holiday"
        else {
          return tConv24(post.monday.from) + " - " + tConv24(post.monday.to)
        }
      case "Tuesday":
        if (post.tuesday.holiday) return "Holiday"
        else {
          return tConv24(post.tuesday.from) + " - " + tConv24(post.tuesday.to)
        }
      case "Wednesday":
        if (post.wednesday.holiday) return "Holiday"
        else {
          return (
            tConv24(post.wednesday.from) + " - " + tConv24(post.wednesday.to)
          )
        }
      case "Thursday":
        if (post.thursday.holiday) return "Holiday"
        else {
          return tConv24(post.thursday.from) + " - " + tConv24(post.thursday.to)
        }
      case "Friday":
        if (post.friday.holiday) return "Holiday"
        else {
          return tConv24(post.friday.from) + " - " + tConv24(post.friday.to)
        }
      case "Saturday":
        if (post.saturday.holiday) return "Holiday"
        else {
          return tConv24(post.saturday.from) + " - " + tConv24(post.saturday.to)
        }
      case "Sunday":
        if (post.sunday.holiday) return "Holiday"
        else {
          return tConv24(post.sunday.from) + " - " + tConv24(post.sunday.to)
        }
      default:
        return ""
    }
  }
  const filter = {
    geoLocation: post.geoLocation[0],
    slug: post.slug,
  }

  return (
    <div>
      <SEOHeader
        title={Title + "  " + post.spaceCity + " | GoFloaters"}
        description={Title + " : " + post.spaceDesc}
        socialURL={post.spaceImage}
        pinterest="true"
      ></SEOHeader>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
          integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
          crossorigin="anonymous"
          defer
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
          crossorigin="anonymous"
          defer
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
          crossorigin="anonymous"
          defer
        ></script>
        <script type="application/ld+json">{`{"@context":"http://schema.org","@type":"LocalBusiness","@id":"${
          post.slug
        }","name":"${
          post.spaceDisplayName + " - " + post.OriginalName
        }","telephone":"+91 7338 7308 00","url":"https://gofloaters.com/meeting-space/${
          post.slug
        }/","address":{"@type":"PostalAddress","addressCountry":"IN","streetAddress":"${
          post.spaceAddress
        }","addressLocality":"${
          post.spaceCity
        }","addressRegion":"IN"},"geo":{"@type":"GeoCoordinates","latitude":"${
          post.geoLocation[0]
        }","longitude":"${
          post.geoLocation[1]
        }"},"email":"support@gofloaters.com","image":{"@type":"ImageObject","url":"${
          post.photos[0]
        }"}}`}</script>
      </Helmet>
      <Layout type="CoworkingSpace">
        {scrollPosition.scrollY > 800 ? (
          <div className="topBarCoworking">
            <div className="container">
              <div className="row">
                <div className="col-md-8 DesktopOnly">
                  <br></br>
                  <br></br>
                  <ul className="coworkingLinks">
                    <li className={currentTab === "overview" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#overview")
                          setcurrentTab("overview")
                        }}
                      >
                        Overview
                      </a>
                    </li>
                    <li className={currentTab === "amenities" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#amenities")
                          setcurrentTab("amenities")
                        }}
                      >
                        Amenities
                      </a>
                    </li>
                    <li className={currentTab === "opening" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#opening")
                          setcurrentTab("opening")
                        }}
                      >
                        Opening Hours
                      </a>
                    </li>
                    <li className={currentTab === "location" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#location")
                          setcurrentTab("location")
                        }}
                      >
                        Location
                      </a>
                    </li>
                    <li className={currentTab === "rating" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#rating")
                          setcurrentTab("rating")
                        }}
                      >
                        Reviews
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 DesktopOnly">
                  {post.spaceType !== "Cafe" ? (
                    <h5 style={{ margin: "0", fontWeight: "bold" }}>
                      <br></br>
                      {post.OriginalName}
                    </h5>
                  ) : (
                    <h5 style={{ margin: "0", fontWeight: "bold" }}>
                      {post.spaceAddress}
                      <br></br>
                    </h5>
                  )}
                  {post.spaceType === "Cafe" ? (
                    <p>
                      {post.spaceDisplayName} in {post.spaceAddress},{" "}
                      {post.spaceCity}
                    </p>
                  ) : (
                    <p>
                      {post.spaceDisplayName} in {post.spaceAddress},{" "}
                      {post.spaceCity}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          itemscope
          itemtype="https://schema.org/LocalBusiness"
          className="container newspaceDetailContainer"
        >
          <div className="row">
            <div className="col-md-12 addressBar">
              <ol itemscope itemtype="https://schema.org/BreadcrumbList">
                <li
                  style={{ paddingLeft: "0" }}
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <Link itemprop="item" to="/coworking-spaces/">
                    <span itemprop="name">Coworking Spaces</span>
                  </Link>
                  <meta itemprop="position" content="1" />
                </li>
                ›
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <Link
                    to={
                      "/coworking-spaces/" +
                      post.spaceCity.toLowerCase().replace(" ", "-") +
                      "/all/"
                    }
                    itemscope
                    itemtype="https://schema.org/WebPage"
                    itemprop="item"
                    itemid={
                      "/coworking-spaces/" +
                      post.spaceCity.toLowerCase().replace(" ", "-") +
                      "/all/"
                    }
                  >
                    <span itemprop="name">{post.spaceCity}</span>
                  </Link>
                  <meta itemprop="position" content="2" />
                </li>
                ›
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <span itemprop="name">
                    {post.spaceDisplayName} in {post.spaceAddress},{" "}
                    {post.spaceCity} ({post.spaceGFName})
                  </span>
                  <meta itemprop="position" content="3" />
                </li>
              </ol>
            </div>
          </div>
          <div className="row specialPadding">
            <div className="col-md-12 col-12">
              {post.spaceType === "Cafe" ? (
                <h1 className="coworkingTitle" itemprop="name">
                  {post.spaceDisplayName} in {post.spaceAddress},{" "}
                  {post.spaceCity}
                </h1>
              ) : (
                <h1 className="coworkingTitle" itemprop="name">
                  {post.spaceDisplayName} in {post.spaceAddress},{" "}
                  {post.spaceCity}
                </h1>
              )}
              {post.spaceType !== "Cafe" ? (
                <h2 className="coworkingLocationTitle">
                  {post.OriginalName}
                  <br></br>
                </h2>
              ) : (
                <h2 className="coworkingLocationTitle">
                  {post.spaceAddress}
                  <br></br>
                </h2>
              )}
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12  ">
              <div className="row">
                <div className="col-md-8">
                  {Photos ? (
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      autoPlay={false}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      containerClassName="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      deviceType={props.deviceType}
                      dotListClassName="custom-dot-list-style"
                    >
                      {Photos}
                    </Carousel>
                  ) : (
                    ""
                  )}
                  <br></br>
                  <div className="MobileOnly">
                    {post.avgRating ? (
                      <p>
                        <div
                          itemprop="aggregateRating"
                          itemscope
                          itemtype="https://schema.org/AggregateRating"
                          className="aggregateRating"
                        >
                          <span style={{ color: "#101010" }}>
                            {" "}
                            {post.avgRating}
                          </span>{" "}
                          <span
                            class={
                              "stars-container stars-" +
                              Number(20) * Number(post.avgRating)
                            }
                          >
                            ★★★★★
                          </span>
                          {"   "}
                          <br />
                          <a onClick={() => scrollTo("#rating")}>
                            See all{" "}
                            <span itemprop="reviewCount">
                              {Number(post.spaceFeedback.length) +
                                Number(post.googleReviewCount)}
                            </span>{" "}
                            review
                            {post.spaceFeedback.length > 1 ? "s" : ""}
                          </a>
                          <br />
                          <br />
                        </div>
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  {SpaceSuitedFor !== "" ? (
                    <div>
                      <h4>Space Best Suited for</h4>
                      {SpaceSuitedFor}
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <div className="row amenties">
                      <div className="col-md-12">
                        <h4 id="amenities">Amenities</h4>
                        <VizSensor
                          onChange={() => {
                            setcurrentTab("amenities")
                          }}
                        >
                          <span className="m-0 ">Space info</span>
                        </VizSensor>
                      </div>
                      {post.Facility.search("Hi Speed WiFi") > 1 ? (
                        <div className="col-md-4 col-6">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/hi_speed_wifi.svg"
                                alt="Hi Speed WiFi"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Hi Speed WiFi</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("Power Backup") > 1 ? (
                        <div className="col-md-4 col-6">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/power_backup.svg"
                                alt="Power Backup"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Power Backup</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("WiFi Backup") > 1 ? (
                        <div className="col-md-4 col-6">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/wifi_backup.svg"
                                alt="WiFi Backup"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p> WiFi Backup</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("Projector") > 1 ? (
                        <div className="col-md-4 col-6">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/projectors.svg"
                                alt="Projector"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Projector</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("Television") > 1 ? (
                        <div className="col-md-4 col-6">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/television.svg"
                                alt="Television"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Television</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("Whiteboard") > 1 ? (
                        <div className="col-md-4 col-6">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/white_board.svg"
                                alt="Whiteboard"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Whiteboard</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>{" "}
                  <br></br>
                  <p>Building Info</p>{" "}
                  <div className="row amenties">
                    {post.Facility.search("AC") > 1 ? (
                      <div className="col-md-4 col-6">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/ac.svg"
                              alt="AC"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>AC</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Metro Nearby") > 1 ? (
                      <div className="col-md-4 col-6">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/metro_nearby.svg"
                              alt="Metro Nearby"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Metro Nearby</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Free Parking") > 1 ? (
                      <div className="col-md-4 col-6">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/free_parking.svg"
                              alt="Free Parking"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Free Parking</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Rest Rooms") > 1 ? (
                      <div className="col-md-4 col-6">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/rest_rooms.svg"
                              alt="Rest Rooms"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Rest Rooms</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Coffee/Tea") > 1 ? (
                      <div className="col-md-4 col-6">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/coffee_tea.svg"
                              alt="Coffee/Tea"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Coffee/Tea</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Pantry") > 1 ? (
                      <div className="col-md-4 col-6">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/pantry.svg"
                              alt="Pantry"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Pantry</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Meeting Rooms") > 1 ? (
                      <div className="col-md-4 col-6">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/meeting_rooms.svg"
                              alt="Meeting Rooms"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Meeting Rooms</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Outside Food Allowed") > 1 ? (
                      <div className="col-md-4 col-6">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/outside_food_allowed.svg"
                              alt="Outside Food Allowed"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Outside Food Allowed</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <br />
                  <div
                    style={{
                      padding: "10px 15px 20px",
                      border: "1px solid #2193b0",
                      borderRadius: "10px",
                      backgroundImage:
                        "linear-gradient(rgba(234,202,175,0.49), rgba(188,175,146,.1))",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <h4 id="amenities">GoFloaters Advantage</h4>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/lower-price.svg"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              Lowest Price Guaranteed
                            </h2>
                            {/* <p>
                            You can trust us with our fair and dependable
                            prices.
                          </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/free-rescheduling.svg"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              Free Rescheduling
                            </h2>
                            {/* <p>
                            You can modify the date,time and other details of
                            your scheduled booking .
                          </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/free-cancellation.svg"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              Free Cancellation
                            </h2>
                            {/* <p>You can cancel booking upto 48 hours</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/instant-confirmations.png"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "17px" }}
                            >
                              Instant Confirmations
                            </h2>
                            {/* <p>You can cancel booking upto 48 hours</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row aligner paddingTriggerTwo">
                          <div className="col-md-3 col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/verified-listings.png"
                              style={{ width: "36px" }}
                            ></img>
                          </div>
                          <div className="col-md-9 col-9">
                            <h2
                              style={{ fontWeight: "bold", fontSize: "17px" }}
                            >
                              Verified listings
                            </h2>
                            {/* <p>You can cancel booking upto 48 hours</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {post.exclusiveOffer ? (
                    <div>
                      <h4>Remarks</h4> <p>{post.exclusiveOffer}</p>
                      <br></br>
                    </div>
                  ) : (
                    <p> </p>
                  )}
                  {post.spaceDesc ? (
                    <div id="overview">
                      <br></br>
                      <h4>About this space</h4>{" "}
                      <VizSensor
                        onChange={() => {
                          setcurrentTab("overview")
                        }}
                      >
                        <p itemprop="description">{post.spaceDesc}</p>
                      </VizSensor>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <br></br>
                    <h4 id="opening">Opening Hours</h4>
                    <VizSensor
                      onChange={() => {
                        setcurrentTab("opening")
                      }}
                    >
                      <b>Today ({todayDay})</b>
                    </VizSensor>
                    <br />
                    {timeCal(todayDay)}
                    <br />
                    <br />
                    <div
                      className="row"
                      style={{
                        padding: "10px 5px",
                        margin: "0",
                        border: "1px solid #D9D9D9",
                      }}
                    >
                      {operationTime}
                    </div>
                  </div>
                  <br></br>
                  <div className="MobileOnly">
                    <VizSensor
                      onChange={() => {
                        setcurrentTab("location")
                      }}
                    >
                      {post.geoLocation !== "" ? (
                        <SimpleMap
                          lat={post.geoLocation[0]}
                          lng={post.geoLocation[1]}
                        ></SimpleMap>
                      ) : (
                        "<p></p>"
                      )}
                    </VizSensor>
                  </div>
                  <br></br>{" "}
                  <div className="row" id="rating">
                    <VizSensor
                      onChange={() => {
                        setcurrentTab("rating")
                      }}
                    >
                      <div className="col-md-8">
                        <br></br>
                        <h4>Reviews</h4>
                        <ul className="reviewTab">
                          {post.spaceFeedback.length > 0 ? (
                            <li
                              onClick={() => {
                                setReviewTab("GoFloaters")
                              }}
                              className={reviewTab === "GoFloaters" && "active"}
                            >
                              <p>
                                GoFloaters Review ({post.spaceFeedback.length})
                              </p>
                            </li>
                          ) : (
                            ""
                          )}
                          {post.googleReviewCount > 0 ? (
                            <li
                              onClick={() => {
                                setReviewTab("Google")
                              }}
                              className={reviewTab === "Google" && "active"}
                            >
                              <p>Google Review ({post.googleReviewCount})</p>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                        {reviewTab === "GoFloaters" &&
                        post.spaceFeedback.length > 0 ? (
                          <div>
                            <p className="customerRatingTime">
                              {post.spaceFeedback.length} reviews
                            </p>
                            {post.spaceFeedback.length > 0
                              ? spaceFeedbackComp
                              : "No Review"}
                          </div>
                        ) : (
                          <div>
                            <br />
                            <p style={{ fontSize: "18px" }}>
                              <b style={{ fontSize: "24px", color: "#1e8aa5" }}>
                                {post.googleRating}
                              </b>{" "}
                              Rating from{" "}
                              <b style={{ color: "#1e8aa5" }}>
                                {post.googleReviewCount}
                              </b>{" "}
                              reviews
                            </p>
                            <br />

                            <p>
                              {post.googleMapsLink ? (
                                <a
                                  style={{ fontSize: "18px", color: "#1e8aa5" }}
                                  href={post.googleMapsLink}
                                  target="_blank"
                                >
                                  {" "}
                                  Visit Google Map Review &gt;&gt;
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </VizSensor>
                  </div>
                  <br></br>
                  <h4>Space FAQ's</h4>
                  <div className="accordion" id="accordionExample">
                    {post.faqs.map((faq, index) => (
                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id={"heading_" + index}
                        >
                          <h2 className="mb-0 alignerFaq">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target={"#collapse_" + index}
                              aria-expanded={index === 0 ? "true" : ""}
                              aria-controls={"collapse_" + index}
                              onClick={() =>
                                index != FAQ ? setFAQ(index) : setFAQ(-1)
                              }
                            >
                              {faq.question}
                            </button>{" "}
                            <i
                              className={
                                FAQ != index
                                  ? "fa fa-chevron-right"
                                  : "fa fa-chevron-down"
                              }
                            ></i>
                          </h2>
                        </div>

                        <div
                          id={"collapse_" + index}
                          className={
                            index === 0
                              ? "accordion-collapse collapse show"
                              : "accordion-collapse collapse"
                          }
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">{faq.answer}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br></br>
                  <h4>Space Overview</h4>
                  <p
                    className="spaceOverviewtext"
                    dangerouslySetInnerHTML={{ __html: post.spaceOverview }}
                  ></p>
                  <br></br> <br></br>
                  <div className="row">
                    <CoworkingRelated filter={filter} />
                  </div>
                </div>
                <div className="col-md-4 DesktopOnly ">
                  <div className="sticky">
                    <div className="ratingBoxPage">
                      {post.avgRating ? (
                        <p>
                          <div
                            itemprop="aggregateRating"
                            itemscope
                            itemtype="https://schema.org/AggregateRating"
                            className="aggregateRating"
                          >
                            <span style={{ color: "#101010" }}>
                              {" "}
                              {post.avgRating}
                            </span>{" "}
                            <span
                              class={
                                "stars-container stars-" +
                                Number(20) * Number(post.avgRating)
                              }
                            >
                              ★★★★★
                            </span>
                            {"   "}
                            <br />
                            <a onClick={() => scrollTo("#rating")}>
                              See all{" "}
                              <span itemprop="reviewCount">
                                {Number(post.spaceFeedback.length) +
                                  Number(post.googleReviewCount)}
                              </span>{" "}
                              review
                              {post.spaceFeedback.length > 1 ? "s" : ""}
                            </a>
                            <br />
                            <br />
                            {/* <div className="reviewBoxOuter">
                                                            {Reviews.length > 0 ? (
                                                                <Carousel
                                                                    swipeable={true}
                                                                    draggable={true}
                                                                    showDots={true}
                                                                    responsive={responsive}
                                                                    ssr={true} // means to render carousel on server-side.
                                                                    autoPlay={false}
                                                                    autoPlaySpeed={3000}
                                                                    keyBoardControl={true}
                                                                    containerClassName="carousel-container"
                                                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                                                    deviceType={props.deviceType}
                                                                    dotListClassName="custom-dot-list-style"
                                                                >
                                                                    {Reviews}
                                                                </Carousel>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div> */}
                          </div>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="DesktopOnly">
                      <div className="ratingBoxPage" style={{ padding: 0 }}>
                        <VizSensor
                          onChange={() => {
                            setcurrentTab("location")
                          }}
                        >
                          {post.geoLocation !== "" ? (
                            <SimpleMap
                              lat={post.geoLocation[0]}
                              lng={post.geoLocation[1]}
                              height="200px"
                            ></SimpleMap>
                          ) : (
                            "<p></p>"
                          )}
                        </VizSensor>
                      </div>
                    </div>

                    <div className="ratingBoxPage ">
                      <div className="priceCloumn row">
                        {post.hourPassAvailable && post.priceHr !== " " ? (
                          <div className="PriceTagnew col-6">
                            <p>
                              {post.priceperhrPreDiscount ? (
                                <b className="priceStrike">
                                  ₹ {post.priceperhrPreDiscount}
                                  <br />
                                </b>
                              ) : (
                                ""
                              )}

                              <b>₹ {post.priceHr}</b>
                              <span>/hour</span>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {post.dayPassAvailable && post.priceDay !== " " ? (
                          <div className="PriceTagnew col-6">
                            <p>
                              {post.priceperdayPreDiscount ? (
                                <b className="priceStrike">
                                  ₹ {post.priceperdayPreDiscount} <br />
                                </b>
                              ) : (
                                ""
                              )}

                              <b>₹ {post.priceDay}</b>
                              <span>/day</span>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="spaceButtonAlter">
                        {post.online ? (
                          <Link to={"/booking?spaceid=" + post.spaceId}>
                            Book Now
                          </Link>
                        ) : (
                          <a
                            href={
                              "https://www.gofloaters.com/app/#/home/explore/bookingrequest/" +
                              post.spaceId
                            }
                            target="_blank"
                            className="disabled"
                          >
                            Currently unavailable
                          </a>
                        )}
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            color: "#878787",
                            paddingTop: "10px",
                          }}
                        >
                          {/* You won’t be charged yet */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="MobileOnly FloatButtonBottom">
                <div className="container">
                  <div className="row aligner">
                    <div className="col-6">
                      <div className="PriceTagnew">
                        {post.dayPassAvailable && post.priceDay !== " " ? (
                          <p>
                            {post.priceperdayPreDiscount ? (
                              <b className="priceStrike">
                                ₹ {post.priceperdayPreDiscount} <br />
                              </b>
                            ) : (
                              ""
                            )}
                            <b>₹ {post.priceDay}</b> <span>/ Day</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="PriceTagnew">
                        {post.hourPassAvailable && post.priceHr !== " " ? (
                          <p>
                            {post.priceperhrPreDiscount ? (
                              <b className="priceStrike">
                                ₹ {post.priceperhrPreDiscount} <br />
                              </b>
                            ) : (
                              ""
                            )}
                            <b>₹ {post.priceHr}</b> <span>/ Hours</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      <div className="spaceButtonAlter">
                        {post.online ? (
                          <Link to={"/booking?spaceid=" + post.spaceId}>
                            Book now
                          </Link>
                        ) : (
                          // <a data-toggle="modal" data-target="#exampleModal">
                          //   Enquire Now
                          // </a>
                          <a
                            href={
                              "https://www.gofloaters.com/app/#/home/explore/bookingrequest/" +
                              post.spaceId
                            }
                            target="_blank"
                            className="disabled"
                          >
                            Currently unavailable
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        {/* {post.geoLocation !== "" ? (
          <NearBy
            lat={post.geoLocation[0]}
            lng={post.geoLocation[1]}
            spacetype="meetingSpace"
            exclude={post.spaceId}
          ></NearBy>
        ) : (
          ""
        )} */}
      </Layout>
      {/* <div
          className="modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <EnquiryForm spaceId={post.spaceId} />
          </div>
        </div> */}
    </div>
  )
}

export default CoworkingSpace
export const query = graphql`
  query CoworkingSpaceNew($slug: String!) {
    listings(slug: { eq: $slug }) {
      spaceDisplayName
      OriginalName
      spaceCity
      spaceGFName
      spaceId
      spaceImage
      spaceTitle
      spaceType
      subType
      monthPassAvailable
      officeSpaceType
      priceDay
      priceHr
      priceMonth
      photos
      Facility
      operationTime
      hourPassAvailable
      exclusiveOffer
      dayPassAvailable
      purposesList
      spaceAddress
      noiseLevel
      landmark
      spaceDesc
      hasCovidSafeBadge
      securitydeposit
      seat
      slug
      geoLocation
      spaceOverview
      Rating
      priceperdayPreDiscount
      priceperhrPreDiscount
      googleMapsLink
      googleRating
      googleReviewCount
      avgRating
      spaceFeedback {
        customerName
        feedbackDate
        gofloaterrating
        rating
        spaceId
        spaceName
        commentForSpaces
        commentForFloaters
      }
      online
      monday {
        from
        to
        useNow
        holiday
      }
      tuesday {
        from
        to
        useNow
        holiday
      }
      wednesday {
        from
        to
        useNow
        holiday
      }
      thursday {
        from
        to
        useNow
        holiday
      }
      friday {
        from
        to
        useNow
        holiday
      }
      saturday {
        from
        to
        useNow
        holiday
      }
      sunday {
        from
        to
        useNow
        holiday
      }
      nearBy {
        distance
        name
        type
      }
      faqs {
        answer
        question
      }
    }
  }
`
